import React from "react";
import './heartZone.less';

function HeartZone() {

  return (
    <div className="HeartZoneBody">
        <div className="title">Heart Zone</div>
        <div className="container">
            <div className="summary">
            <div className="summary-heading">
                <div className="summary-heading-tag"></div>
                <div className="summary-heading-content">Zone5 Maximum</div>
            </div>
            <div className="summary-content">
                <div className="content">
                <div className="content-body">160+ bpm</div>
            </div>
            </div>
            </div>
            <div className="summary">
            <div className="summary-heading">
                <div className="summary-heading-tag"></div>
                <div className="summary-heading-content">Zone4 Threshold</div>
            </div>
            <div className="summary-content">
                <div className="content">
                <div className="content-body">142-159 bpm</div>
            </div>
            </div>
            </div>
            <div className="summary">
            <div className="summary-heading">
                <div className="summary-heading-tag"></div>
                <div className="summary-heading-content">Zone3 Aerobic</div>
            </div>
            <div className="summary-content">
                <div className="content">
                <div className="content-body">125-141 bpm</div>
            </div>
            </div>
            </div>
            <div className="summary">
            <div className="summary-heading">
                <div className="summary-heading-tag"></div>
                <div className="summary-heading-content">Zone2 Easy</div>
            </div>
            <div className="summary-content">
                <div className="content">
                <div className="content-body">107-124 bpm</div>
            </div>
            </div>
            </div>
            <div className="summary">
            <div className="summary-heading">
                <div className="summary-heading-tag"></div>
                <div className="summary-heading-content">Zone1 Warm up</div>
            </div>
            <div className="summary-content">
                <div className="content">
                <div className="content-body">89-106 bpm</div>
            </div>
            </div>
            </div>
        </div>
    </div>
  );
}

export default HeartZone;