import React from "react";
import './index.less';

function Todo() {

  return (
    <div className="todoBody">
      <div className="week-summary">Week Summary</div>
      <div className="summary-container">
        <div className="summary">
          <div className="summary-heading">
            <div className="summary-heading-tag"></div>
            <div className="summary-heading-content">Running</div>
          </div>
          <div className="summary-content">
            <div className="content">
              <div className="content-heading">Dis.</div>
              <div className="content-body">12.7km</div>
            </div>
            <div className="content">
              <div className="content-heading">AVs.</div>
              <div className="content-body">5'47"</div>
            </div>
            <div className="content">
              <div className="content-heading">Sustain Avs.</div>
              <div className="content-body">4'57"</div>
            </div>
          </div>
        </div>
        <div className="summary">
          <div className="summary-heading">
            <div className="summary-heading-tag"></div>
            <div className="summary-heading-content">Swimming</div>
          </div>
          <div className="summary-content">
            <div className="content">
              <div className="content-heading">Dis.</div>
              <div className="content-body">2.5km</div>
            </div>
            <div className="content">
              <div className="content-heading">AVs.</div>
              <div className="content-body">4'26"</div>
            </div>
            <div className="content">
              <div className="content-heading">Sustain Avs.</div>
              <div className="content-body">4'03"</div>
            </div>
          </div>
        </div>
        <div className="summary">
          <div className="summary-heading">
            <div className="summary-heading-tag"></div>
            <div className="summary-heading-content">Biking</div>
          </div>
          <div className="summary-content">
            <div className="content">
              <div className="content-heading">Dis.</div>
              <div className="content-body">30.4km</div>
            </div>
            <div className="content">
              <div className="content-heading">AVs.</div>
              <div className="content-body">14mph</div>
            </div>
            <div className="content">
              <div className="content-heading">Sustain Avs.</div>
              <div className="content-body">17mph</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Todo;
